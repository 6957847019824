<template>
    <div>
        <LiefengTable
            class="liefeng-table"
            :talbeColumns="columns"
            :tableData="tableData"
            :fixTable="true"
            :curPage="pageForm.page"
            :total="pageForm.total"
            :pagesizeOpts="[20, 30, 50, 100]"
            :page-size="pageForm.size"
            :backgroundText="watermark"
            @hadlePageSize="onChangeTableSize"
        ></LiefengTable>
    </div>
</template>

<script>
import CompModal from "../components/CompModal.vue"
import LiefengTable from "@/components/LiefengTable.vue"
import CanvasUtils from "../utils/canvas_utils"
import qs from "qs"

export default {
    components: { CompModal, LiefengTable },

    data() {
        return {
            columns: [],
            tableData: [],

            pageForm: {
                page: 1,
                total: 0,
                size: 20,
            },
            watermark: null,

            searchObj: {},
        }
    },
    created() {
        this.searchObj = qs.parse(this.$route.query)
        console.log("searchObj", this.searchObj)

        this.getTableData()
    },
    mounted() {
        const canvas = new CanvasUtils(10)
        const userinfo = parent.vue.loginInfo.userinfo
        // 逆时针旋转 30 度
        canvas.rotate(-30)
        // 绘制登录人信息 & 当前时间
        canvas.draw(
            `${userinfo.realName} ${userinfo.mobile.length >= 4 ? userinfo.mobile.substring(userinfo.mobile.length - 4, userinfo.mobile.length) : ""}`,
            this.$core.formatDate(new Date(), "MM-dd hh:mm")
        )
        // 获取 base64 图片
        this.watermark = canvas.toImage()
        // 销毁
        canvas.destroy()
    },
    methods: {
        getTableData() {
            this.getCollectTableData()
        },
        onChangeTableSize(val) {
            this.pageForm.page = val.page
            this.pageForm.size = val.pageSize
            // 加载数据
            this.getTableData()
        },
        getCondition() {
            var list = []
            if (this.searchObj.search) {
                // 服务记录
                var searchJson = this.$route.query.search
                searchJson.split(",").map(item => {
                    list.push({
                        queryFieldId: "mobile",
                        queryFieldValue: item,
                        searchMethod: "Equal",
                    })
                })
            } else {
                for (let key in this.searchObj.paramsValueMap) {
                    if (this.searchObj.paramsValueMap.hasOwnProperty(key)) {
                        if (key != "appId") {
                            console.log(this.searchObj.paramsValueMap[key])
                            list = [
                                {
                                    queryFieldId: key,
                                    queryFieldValue: this.searchObj.paramsValueMap[key],
                                    searchMethod:'Equal' ,
                                },
                            ]
                        }
                    }
                }
                // 新的记录
            }

            return list
        },
        getCollectTableData() {
            const load = this.$Message.loading("加载中...")
            if (this.searchObj.search) {
                this.$post(
                    `/gateway/api/fastDev/AppData/findPageData?appId=${this.$route.query.appId && this.$route.query.appId != "" ? this.$route.query.appId : ""}&page=${this.pageForm.page}&pageSize=${
                        this.pageForm.size
                    }`,
                    this.getCondition(),
                    {
                        "Content-Type": "application/json",
                    }
                )
                    .then(res => {
                        if (res.code == 200) {
                            const data = res.data
                            this.pageForm.total = data.maxCount
                            this.processColumns(data)
                        } else this.$Message.error(res.desc.slice(0, 50))
                    })
                    .finally(load)
            } else {
                this.$post(`/gateway/api/fastDev/AppData/findPageData?appId=${this.searchObj.paramsValueMap.appId}&page=${this.pageForm.page}&pageSize=${this.pageForm.size}`, this.getCondition(), {
                    "Content-Type": "application/json",
                })
                    .then(res => {
                        if (res.code == 200) {
                            const data = res.data
                            this.pageForm.total = data.maxCount
                            this.processColumns(data)
                        } else this.$Message.error(res.desc.slice(0, 50))
                    })
                    .finally(load)
            }
        },

        processColumns(data) {
            const columns = [
                {
                    title: "序号",
                    type: "index",
                    width: 80,
                    align: "center",
                },
            ]

            const columnNameList = data.columnNameList
            const minWidth = {}

            for (let i = 0; i < columnNameList.length; i++) {
                const c = columnNameList[i]

                let width = this.getMinWidth(c)

                minWidth[`key-${i}`] = width

                columns.push({
                    title: c,
                    key: `key-${i}`,
                    minWidth: width,
                    align: "center",
                    render: (h, params) => {
                        const value = params.row[`key-${i}`]

                        // 判断值是否为图片链接
                        if (/http.*\.(jpg|jpeg|png)/.test(value)) {
                            var images

                            // 是否为数组
                            if (/^\[.*\]$/.test(value)) {
                                images = JSON.parse(value)
                            } else if (/\|/.test(value)) {
                                images = value.split("|")
                            } else if (/,/.test(value)) {
                                images = value.split(",")
                            } else {
                                images = [value]
                            }

                            const files = []

                            images?.forEach(item => {
                                if (!item) return
                                files.push(
                                    h("div", {
                                        style: {
                                            width: "50px",
                                            height: "50px",
                                            margin: "3px",
                                            background: `url(${item})`,
                                            "background-size": "cover",
                                            "background-position": "center",
                                            "border-radius": "3px",
                                            cursor: "pointer",
                                        },
                                        on: {
                                            click: () => {
                                                this.$refs.preview.display(item, images)
                                            },
                                        },
                                    })
                                )
                            })

                            return h(
                                "div",
                                {
                                    style: {
                                        display: "flex",
                                        "justify-content": "center",
                                        "flex-wrap": "wrap",
                                        margin: "5px 0",
                                    },
                                },
                                files
                            )
                        }

                        return h("p", value)
                    },
                })
            }

            // 最后添加操作列
            // columns.push(this.getOperate())

            const tableData = []
            const dataList = data.dataList

            for (let i = 0; i < dataList.length; i++) {
                const d = dataList[i]

                const obj = {
                    id: d.formDataId || d.id,
                }

                for (let ci = 0, cs = d.columnDataList; ci < cs.length; ci++) {
                    let text = cs[ci]

                    // 没有值，跳过
                    if (!text) {
                        obj[`key-${ci}`] = "-"
                        continue
                    }

                    // 去除数组中括号
                    if (/^\[.*\]$/.test(text)) {
                        text = text.replace(/[\[\]"]/g, "")
                    }

                    let width = this.getMinWidth(text)

                    if (minWidth[`key-${ci}`] < width) {
                        minWidth[`key-${ci}`] = width
                    }

                    obj[`key-${ci}`] = text
                }

                tableData.push({ ...obj, buttonList: d.buttonList })
            }

            for (let i = 0; i < columns.length; i++) {
                let v = columns[i]
                v.minWidth = minWidth[v.key]
            }

            this.columns = columns
            this.tableData = tableData
        },

        /**
         * 获取最小宽度值
         */
        getMinWidth(text) {
            // 普通字符 20 px，中文加多 15 px/字
            let size = text.length * 20 + (text.match(/[\u4E00-\u9FA5]/g)?.length || 0) * 15
            // 最低默认宽度为 80
            return size > 80 ? (size > 300 ? 300 : size) : 80
        },
    },
}
</script>

<style lang="less">
.records-model {
    .scroll-content {
        display: flex;
        flex-direction: column;
    }

    .screen-box {
        padding: 5px;
        display: flex;
        overflow: auto;

        .item-box {
            margin: 5px;
            flex-shrink: 0;
        }

        .btn {
            margin: 5px;
            cursor: pointer;
            padding: 5px 0;
            width: 105px;
            color: #fff;
            background: #2faaf7;
            font-size: 14px;
            border: 0;
            display: flex;
            -webkit-transition: all 0.3s;
            transition: all 0.3s;
            -webkit-transition-timing-function: ease;
            transition-timing-function: ease;
            border-radius: 5px;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;

            &.reset {
                background: #19be6b;
            }
        }
    }

    .table-box {
        flex: 1;
        height: initial;
        margin-top: 0;
    }
}

.comp-model .table-box {
    position: relative;
    width: 100%;
    height: ~"calc(100% - 10px)";
    margin-top: 10px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-grow: 1;
    border-bottom: 1px solid #f3f3f3;

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    .base-box {
        min-width: 100%;
        flex-shrink: 0;
        display: flex;
        align-items: flex-start;

        .content-box {
            min-height: 100%;
            min-width: 100%;
            padding: 0 5px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .head {
                position: -webkit-sticky;
                position: sticky;
                top: 0;
                min-width: 100%;
                height: 60px;
                background: #f3f3f3;
                z-index: 10;
                border-radius: 5px;
                display: flex;

                .item {
                    padding: 0 20px;
                    width: 200px;
                    height: 100%;
                    font-size: 15px;
                    color: #abb4b9;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .body {
                min-width: 100%;
                max-width: 100%;

                .row {
                    position: relative;
                    height: 50px;
                    z-index: 1;
                    border: 1px solid transparent;
                    border-radius: 5px;
                    display: flex;
                    -webkit-transition: all 0.3s;
                    transition: all 0.3s;
                    -webkit-transition-timing-function: ease;
                    transition-timing-function: ease;
                    box-sizing: border-box;

                    &:hover {
                        z-index: 5;
                        border-color: #e3e3e3;
                        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
                    }

                    &:nth-child(2n) {
                        background: #f9f9f9;
                    }

                    .column {
                        padding: 0 20px;
                        width: 200px;
                        height: 100%;

                        .column-box {
                            margin: 3px 0;
                            width: 100%;
                            height: ~"calc(100% - 3px)";
                            letter-spacing: 1px;
                            color: #000;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            overflow-x: auto;
                            overflow-y: hidden;
                            -webkit-overflow-scrolling: touch;

                            &::-webkit-scrollbar {
                                height: 5px;
                            }

                            .text-box {
                                font-size: 13px;
                                max-width: 90%;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            &:hover .text-box {
                                overflow: initial;
                                text-overflow: initial;
                            }

                            .normal-c {
                                color: #07cd6f;
                            }

                            .normal-bg {
                                background: #07cd6f;
                            }

                            .empty-c {
                                color: #ffbb3a;
                            }

                            .empty-bg {
                                background: #ffbb3a;
                            }

                            .subclass-box {
                                cursor: pointer;
                                display: flex;
                                align-items: center;
                                -webkit-transition: all 0.3s;
                                transition: all 0.3s;
                                -webkit-transition-timing-function: ease;
                                transition-timing-function: ease;

                                .point {
                                    width: 8px;
                                    height: 8px;
                                    border-radius: 100%;
                                }

                                .text {
                                    margin-left: 10px;
                                }
                            }
                        }

                        .column-box-null {
                            color: #666;
                        }
                    }
                }

                .empty {
                    text-align: center;
                    font-size: 15px;
                    color: #888;
                    line-height: 25px;
                    position: absolute;
                    top: 80px;
                    right: 0;
                    left: 0;
                }
            }
        }

        .operating-box {
            background: #fff;
            z-index: 10;
            min-height: 100%;
            padding-right: 5px;
            flex-grow: 1;
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .operating-title {
                position: sticky;
                top: 0;
                min-width: 100%;
                padding: 0 30px;
                height: 60px;
                font-size: 15px;
                color: #abb4b9;
                font-weight: bold;
                background: #f3f3f3;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                border-radius: 5px;
            }

            .operating-item {
                padding: 0 30px;
                height: 50px;
                letter-spacing: 1px;
                color: #000;
                min-width: 100%;
                box-sizing: border-box;
                display: flex;
                align-items: center;

                &:nth-child(2n-1) {
                    background: #f9f9f9;
                }

                .button-box {
                    margin-right: 10px;

                    &:last-child {
                        margin-right: 0;
                    }

                    a,
                    button {
                        cursor: pointer;
                        padding: 5px 12px;
                        color: #fff;
                        font-size: 14px;
                        border: 0;
                        display: flex;
                        -webkit-transition: all 0.3s;
                        transition: all 0.3s;
                        -webkit-transition-timing-function: ease;
                        transition-timing-function: ease;
                        border-radius: 5px;

                        &:hover {
                            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                        }
                    }
                }

                .edit {
                    background: #00b3d9;
                }

                .delete {
                    background: #ff5722;
                }

                .entity {
                    background: #2faaf7;
                }
            }
        }

        .operating-suspension {
            position: sticky;
            right: 0;
            box-shadow: -5px 0 5px rgba(0, 0, 0, 0.1);

            .operating-title {
                border-radius: 0 5px 5px 0;
            }
        }
    }
}
</style>
